export function registerBeta({ email, origin }) {
    return fetch(`https://api.thefoodapi.com/beta/registration`, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email,
            origin
        })
    })
}

export function submitFeatureRequest({ title, description, email }) {
    return fetch(`https://api.thefoodapi.com/feature/request`, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ title, body: description, email })
    })
}

export function queryFoodInformation({ userQuery }) {
    return fetch(`https://api.thefoodapi.com/info/${userQuery.code_name || userQuery.title}`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJkZXYiLCJvcmlnaW5zIjpbImh0dHA6Ly9sb2NhbGhvc3Q6ODAwMCJdLCJpYXQiOjE2NzQzMzI5NjYsImF1ZCI6InRoZWZvb2RhcGkuY29tIn0.VePwN_d9YQKScTEshqEgxeymdD5KdubUemifBhD8coY"
        }
    })
}

export function getSuggestions({ value }) {
    return fetch(`https://api.thefoodapi.com/autocomplete/${value}`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
}
