import * as React from "react"
import Layout from "../components/layout"
import '../components/roadmap.css'
import { useState } from "react";
import { useEffect } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField } from "@mui/material";
import registerBeta, { submitFeatureRequest } from '../utils/api'
import RedditIcon from '@mui/icons-material/Reddit';
import ClearIcon from '@mui/icons-material/Clear';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: "calc(90% - 64px)",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const pageStyles = {
  color: "#232129",
  padding: "96px 12px",
  fontFamily: "Roboto, sans-serif, serif",
  maxWidth: "895px",
  margin: "auto",
  minHeight: "70vh",
  width: "100%"
}


function saveUpvote(info) {
  fetch("https://api.thefoodapi.com/upvote", {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ feature: info })
  })
}


const RoadmapItem = ({ details, visitorUpvote, locked }) => {
  const [firstRender, setFirstRender] = useState(true)
  const [userUpvote, setUserUpvote] = useState(visitorUpvote || false)
  const [nVotes, setNVotes] = useState(details.votes || 0)

  useEffect(() => {
    setFirstRender(false)
  }, [])

  useEffect(() => {
    if (firstRender) return

    if (userUpvote) {
      setNVotes(nVotes + 1)
      saveUpvote(details.title)
    }
  }, [userUpvote])

  return <div className="roadmap-row">
    <div className={"votes-ctn" + (userUpvote ? " user-upvoted" : "")}>
      <div className="votes">{nVotes}</div>
      <div
        onClick={() => {
          if (!locked) setUserUpvote(!userUpvote)
        }}
        className={"upvote" + (locked ? " locked" : "")}>
        {/*✋*/}
        {/*<ThumbUpIcon />*/}
        👍
      </div>
    </div>
    <div className="content">
      <div className="title">{details.title}</div>
      <div className="date">{details.date}</div>
    </div>
  </div>
}

const FeatureRequest = () => {
  const [open, setOpen] = React.useState(false);
  const [sendingRequest, setSendingRequest] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className="clickable highlight-hover" onClick={handleOpen} style={{
          padding: "0.5rem",
          border: "1px solid lightgrey",
          borderRadius: "8px",
          display: "flex",
        }}>
          <span>Request new feature</span>
          <div style={{
            textAlign: "right",
            minWidth: "3rem"
          }}>✋</div></div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon onClick={handleClose} sx={{ position: "absolute", top: "0.25rem", right: "0.25rem", cursor: "pointer" }} />
          {(sendingRequest) && <div style={{
            position: "absolute",
            inset: "0",
            backdropFilter: "brightness(0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "2",
            color: "white",
            fontWeight: "bold"
          }}>Sending</div>}
          {success && <div style={{
            position: "absolute",
            inset: "0",
            background: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "2",
            padding: "1rem",
            textAlign: "center"
          }}>
            <h2 style={{ color: "rgb(0, 119, 136)" }}>Success</h2>
            <div style={{ paddingBottom: "4rem" }}>Thank you for submitting a feature request. We will come back to you shortly.</div>
          </div>}
          <h2 style={{ marginTop: "0" }}>Feature request</h2>
          <TextField label="Title" fullwidth style={{ width: "100%", marginTop: "0.5rem", marginBottom: "1rem" }} onChange={(e) => setTitle(e.target.value)} />
          <TextField label="Description" fullwidth style={{ width: "100%", marginBottom: "1rem" }} multiline rows={6} onChange={(e) => setDescription(e.target.value)} />
          <TextField label="Keep me informed (optional)" placeholder="Email" fullwidth style={{ width: "100%", marginBottom: "2rem" }} onChange={(e) => setEmail(e.target.value)} />
          <div className="clickable" style={{
            background: "#007788",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "1rem 0",
            color: "white",
            borderRadius: "4px",
            fontWeight: "bold",
            marginBottom: "0.5rem"
          }} onClick={() => {
            if (title || description || email) submitFeatureRequest({ title, description, email }).then(resp => {
              if (resp.status === 200) {
                setSuccess(true)
                setTitle("")
                setDescription("")
                setEmail("")
              }
            })
          }}>Send request</div>
          <div style={{
            color: "#00778875",
            textAlign: "center",
            padding: "1rem 0"
          }}>Or</div>
          <a href="https://discord.gg/ekEGYrY4" className="clickable" target="_blank" style={{
            textDecoration: "none",
            color: "white",
            background: "#7289da",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
            color: "white",
            borderRadius: "4px",
            fontWeight: "bold",
            marginBottom: "0.5rem"
          }}>
            <span style={{ marginRight: "0.5rem" }}>Join our discord server (NEW)</span>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1.25rem" width="1.25rem" xmlns="http://www.w3.org/2000/svg" ><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg>
          </a>
          <div style={{
            marginTop: "2rem",
            textAlign: "center",
          }}>
            <a
              style={{
                color: "#00778875"
              }}
              href="https://www.privacypolicygenerator.info/live.php?token=fziOD65GhSRDzONVqJcFxdCxZYBSMwDc" target="_blank" alt="Privace policy">Privacy policy</a></div>
        </Box>
      </Modal>
    </div>
  );
}

const Reddit = ({ url }) => {
  return <a href={url} rel="nofollow" target="_blank" style={{
    background: "#ff4500",
    borderRadius: "50%",
    padding: "0.5rem"
  }}>
    <RedditIcon sx={{
      fill: "white",
    }} />
  </a>
}

const ChangeLogRow = ({ changelogContent }) => {
  return <div className="log">
    <div className="date">{changelogContent.date}</div>
    <div className="content">
      <div className="title"><b>{changelogContent.title}</b></div>
      <div className="description">{changelogContent.description}</div>
      <div className="feedback-src">
        <div>Feedback collected from:</div>
        <div className="src">{changelogContent.feedbackSrc}</div>
      </div>
    </div>
  </div>
}

const ChangeLog = () => {
  return <section className="changelog">
    <h2>Changelog</h2>
    <ChangeLogRow changelogContent={{
      date: "18 Jan. 2023",
      title: "Beta V2 - API and data revamp",
      description: <ul>
        <li>Add more food samples to the API</li>
        <li>Add food category to the autocomplete endpoint</li>
        <li>Improve UI and UX as per user feedback</li>
      </ul>,
      feedbackSrc: <><Reddit url="https://www.reddit.com/r/SideProject/comments/zbevy3/i_just_launched_my_side_projects_landing_page/" /></>
    }} />
  </section >
}

const RoadmapPage = ({ location }) => {

  return (
    <Layout location={location}>
      <div style={pageStyles}>
        <FeatureRequest />
        <div className="tasks-col">
          <div className="planned">
            <div className="title" style={{
              color: "#007788"
            }}>
              Planned
            </div>
            <div className="items">
              <RoadmapItem details={{
                votes: 12,
                title: "Add pictures with transparent background",
              }} visitorUpvote={false} />
              <RoadmapItem details={{
                votes: 9,
                title: "Add \"similar dish\" to the search results",
              }} visitorUpvote={false} />
              <RoadmapItem details={{
                votes: 6,
                title: "Convert image to webp format",
              }} visitorUpvote={false} />
              <RoadmapItem details={{
                votes: 4,
                title: "Compress images",
              }} visitorUpvote={false} />
              <RoadmapItem details={{
                votes: 1,
                title: "Launch on ProductHunt",
              }} visitorUpvote={false} locked={true} />
            </div>
          </div>
          <div className="inprogress">
            <div className="title" style={{
              color: "#FD8F3B"
            }}>
              In Progress
            </div>
            <div className="items">
              <RoadmapItem details={{
                votes: 1,
                title: "Open the API to Beta users",
                date: "Target: Feb. 2023"
              }} visitorUpvote={false} locked={true} />
            </div>
          </div>
          <div className="completed">
            <div className="title" style={{
              color: "#20C467"
            }}>
              Completed
            </div>
            <div className="items">
              <RoadmapItem details={{
                votes: 1,
                title: "Launch website",
                date: "03 Dec. 2022"
              }} visitorUpvote={false} locked={true} />
              <RoadmapItem details={{
                votes: 1,
                title: "Add demo API to website",
                date: "03 Dec. 2022"
              }} visitorUpvote={false} locked={true} />
              <RoadmapItem details={{
                votes: 1,
                title: "Add nore food information to the API",
                date: "18 Jan. 2023"
              }} visitorUpvote={false} locked={true} />
            </div>
          </div>
        </div>
        <ChangeLog />
      </div>
    </Layout>
  )
}

export default RoadmapPage

export const Head = () => <title>Roadmap Page</title>
